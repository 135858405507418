import React, {
  useContext, useEffect, useRef, useState,
} from 'react';

import MapContext from '../../MapPage/MapContext';
import { appendSiteSeeGuideRefs } from '../../SiteSee/SiteSeeGuides/SiteSeeGuideHelpers';

import MapControlsZoom from './MapControlsZoom/MapControlsZoom';
import MapControlsMapTypeToggle from './MapControlsMapTypeToggle/MapControlsMapTypeToggle';
import MapControlsPOIToggle from './MapControlsPOIToggle/MapControlsPOIToggle';
import MapControlsMarkersToggle from './MapControlsMarkersToggle/MapControlsMarkersToggle';
import SiteSeeTipTrigger from '../../SiteSee/SiteSeeGuides/SiteSeeTipTrigger';

import './MapControls.scss';

const MapControls = ( {
  setActiveZoom,
  activeZoom,
  zoomSettings,
  setFitAndPan,
  setOverlayVisible,
  setMarkersVisible,
  markersToggleVisible,
  siteSeeGuideId,
  setSiteSeeGuideId,
  showSiteSeeGuide,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
  siteSeeGuideRefs,
  setSiteSeeGuideRefs,
  siteSeeModalDims,
  setSiteSeeModalDims
} ) => {
  const [ enablePOIToggle, setEnablePOIToggle ] = useState( false );

  const {
    mapData,
  } = useContext( MapContext );

  const {
    googleMapsConfig,
  } = mapData;

  useEffect( () => {
    if ( googleMapsConfig ) {
      const { enablePOI } = googleMapsConfig;
      if ( enablePOI ) {
        setEnablePOIToggle( true );
      }
    }
  }, [ googleMapsConfig ] );

  // Pass ref back to sitesee guide.
  const mapControlsRef = useRef( null );
  useEffect( () => {
    const currentRef = mapControlsRef.current;
    if ( currentRef
      && ( siteSeeGuideId === 2 || siteSeeGuideId === 'controls' )
    ) {
      const newSiteSeeGuideRefs = appendSiteSeeGuideRefs(
        currentRef,
        siteSeeGuideId,
        siteSeeGuideRefs,
      );
      setSiteSeeGuideRefs( newSiteSeeGuideRefs );
      setSiteSeeModalDims( currentRef.getBoundingClientRect() );
    }
  }, [ siteSeeGuideId ] );

  return (
    <div className="mapControls__wrapper">

      <div className="mapControls" ref={mapControlsRef}>

        <MapControlsZoom
          setActiveZoom={setActiveZoom}
          activeZoom={activeZoom}
          zoomSettings={zoomSettings}
          siteSeeGuideId={siteSeeGuideId}
          setSiteSeeGuideId={setSiteSeeGuideId}
          showSiteSeeGuide={showSiteSeeGuide}
          setShowSiteSeeGuide={setShowSiteSeeGuide}
          siteSeeGuideMode={siteSeeGuideMode}
        />

        <MapControlsMapTypeToggle />

        { enablePOIToggle
          && <MapControlsPOIToggle /> }

        <MapControlsMarkersToggle
          setMarkersVisible={setMarkersVisible}
          markersToggleVisible={markersToggleVisible}
        />

      </div>

      <SiteSeeTipTrigger
        siteSeeGuideId={siteSeeGuideId}
        setSiteSeeGuideId={setSiteSeeGuideId}
        showSiteSeeGuide={showSiteSeeGuide}
        setShowSiteSeeGuide={setShowSiteSeeGuide}
        siteSeeGuideMode={siteSeeGuideMode}
        siteSeeTipId="controls"
      />

    </div>
  );
};

export default MapControls;
