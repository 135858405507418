const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

function processFavoriteMediaField(serializedIncludedMediaData) {
  let media = [];

  if (serializedIncludedMediaData) {
    // Massage the data into the shape we care about for floorplans.
    media = serializedIncludedMediaData.map((medium) => {
      // get node type
      let type = null;
      if (medium?.fieldGalleryPhotos) {
        type = 'photo_gallery';
      } else if (medium?.fieldThreeSixties) {
        type = 'virtual_tour';
      } else if (medium?.fieldVideoService && medium?.fieldVideoId) {
        type = 'video';
      }

      return {
        id: medium.id,
        type,
      };
    });
  }

  return media;
}

/**
 * Normalize function for drupal residents requested from Personalization Form
 */
// eslint-disable-next-line import/prefer-default-export
export const personalizationNormalizeDrupalData = async (responseData) => {
  const options = {
    keyForAttribute: 'camelCase',
  };
  const normalizedData = await new JSONAPIDeserializer(options).deserialize(
    responseData
  );
  const users = [];
  normalizedData.forEach((userData) => {
    const user = {
      drupalUuid: userData.id,
      drupalNid: userData.drupalInternalNid,
      crmIndividualId: userData.fieldCrmIndividualId,
      crmProspectId: userData.fieldCrmProspectId,
      firstName: userData.fieldFirstName,
      lastName: userData.fieldLastName,
      email: userData.fieldEmail,
      myFavoritesPage: userData.fieldMyFavoritesPage,
      person2DrupalUuid: userData.fieldSpouse?.id,
      person2CrmIndividualId: userData.fieldSpouse?.fieldCrmIndividualId,
      person2FirstName: userData.fieldSpouse?.fieldFirstName,
      person2LastName: userData.fieldSpouse?.fieldLastName,
      person2Email: userData.fieldSpouse?.fieldEmail,
      changed: userData.changed,
      ytSessions: userData.fieldYtSessions,
      favoriteAmenities: userData.fieldFavoriteAmenities,
      favoriteFloorPlans: userData.fieldFavoriteFloorPlans,
      favoriteMedia: processFavoriteMediaField(userData.fieldFavoriteMedia),
      favoritesPageMessage: userData.fieldFavoritesPageMessage.value,
    };
    users.push(user);
  });

  return users;
};
