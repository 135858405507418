
import { humanizeNumber } from '../../utils/common';

export function assembleFloorPlanNavBtnsData( floorPlanData ) {
  const navBtns = [];

  if ( !floorPlanData ) {
    return navBtns;
  }

  const {
    roomFees,
    description,
    floorplan2d,
    floorplan3d,
    roomData,
    threeSixties,
    finishCollection,
    furnitureConfiguratorUrl,
    video,
    photoGalleryImages,
  } = floorPlanData;

  if ( ( roomFees && roomFees.length > 0 ) || description ) {
    navBtns.push( {
      section: 'info-scroll',
      text: 'Info',
    } );
  }

  if ( floorplan3d || floorplan2d ) {
    navBtns.push( {
      section: 'floorplan-scroll',
      text: 'Floor Plan',
    } );
  }

  if ( roomData && roomData.length > 0 ) {
    navBtns.push( {
      section: 'dimensions-scroll',
      text: 'Dimensions',
    } );
  }

  if ( photoGalleryImages && photoGalleryImages.length > 0 ) {
    navBtns.push( {
      section: 'slideshow-scroll',
      text: 'Photos',
    } );
  }

  if ( video ) {
    navBtns.push( {
      section: 'video-scroll',
      text: 'Video',
    } );
  }

  if ( threeSixties && threeSixties.length > 0 ) {
    navBtns.push( {
      section: 'tour-scroll',
      text: 'Tour',
    } );
  }

  if ( furnitureConfiguratorUrl ) {
    navBtns.push( {
      section: 'configurator-scroll',
      text: 'Furniture Layout',
    } );
  }

  if ( finishCollection && Object.keys( finishCollection ).length !== 0 ) {
    navBtns.push( {
      section: 'finishes-scroll',
      text: 'Finishes',
    } );
  }

  return navBtns;
}
export function createBedBathSqFtPricingTableData( data, showPricing ) {
  const { bed, bath, studio, sqft, roomFees, withDen } = data;
  const tableInfo = [];

  if ( ( bed || bath || studio ) ) {
    const updatedBed = withDen === 'Yes' ? `${bed} w/ Den` : bed;
    const bedBathSeperator = bed && bath ? ' & ' : '';
    const bedBathString = studio ? 'Studio' : `${updatedBed}${bedBathSeperator}${bath}`;
    const bedBathInfo = {
      label: 'Bedroom & Bathroom',
      data: bedBathString,
    };
    tableInfo.push( bedBathInfo );
  }

  if ( sqft ) {
    // add comma to sqft
    const humanSqFt = humanizeNumber( sqft );
    const sqftInfo = {
      label: 'Square Footage',
      data: humanSqFt,
    };
    tableInfo.push( sqftInfo );
  }

  if ( roomFees && roomFees.length && showPricing ) {
    for ( let i = 0; i < roomFees.length; i++ ) {
      tableInfo.push( roomFees[i] );
    }
  }

  return tableInfo;
};

export default assembleFloorPlanNavBtnsData;
