import React from 'react';

import MapControlsZoomMore from './MapControlsZoomMore';
import MapControlsZoomLess from './MapControlsZoomLess';
import MapControlsZoomReset from './MapControlsZoomReset';

const MapControlsZoom = ( {
  setActiveZoom,
  activeZoom,
  zoomSettings,
  siteSeeGuideId,
  setSiteSeeGuideId,
  showSiteSeeGuide,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
} ) => (
  <>
    {zoomSettings
        && (
        <>
          <MapControlsZoomMore
            maxZoom={zoomSettings?.max}
            siteSeeGuideId={siteSeeGuideId}
            setSiteSeeGuideId={setSiteSeeGuideId}
            showSiteSeeGuide={showSiteSeeGuide}
            setShowSiteSeeGuide={setShowSiteSeeGuide}
            siteSeeGuideMode={siteSeeGuideMode}
          />
          <MapControlsZoomLess
            setActiveZoom={setActiveZoom}
            activeZoom={activeZoom}
            minZoom={zoomSettings?.min}
          />
        </>
        )}
    <MapControlsZoomReset />
  </>
);

export default MapControlsZoom;
