import React, {
  useState, useRef, useEffect, useContext,
} from 'react';

import MapContext from '../../MapPage/MapContext';
import ZoomZoneIcon from './ZoomZoneIcon';

const ZoomZone = ( {
  zoomZone,
  siteSeeGuideId,
  setSiteSeeGuideId,
  showSiteSeeGuide,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
} ) => {
  const { center, name, orientation } = zoomZone;
  // These values are width and height of SVG received from Sketch.
  const svgWidth = 43;
  const svgHeight = 43;
  const zoomZoneCenter = {
    x: ( center.x - ( svgWidth / 2 ) ),
    y: ( center.y - ( svgHeight / 2 ) ),
  };
  const { mapZoomToEnableLevels, setActiveZoom, mapInstance } = useContext( MapContext );
  const zoomZoneIconRef = useRef( null );
  const textRef = useRef( null );
  const [ svgSize, setSvgSize ] = useState( null );
  const [ rectSize, setRectSize ] = useState( { width: 0, height: 0 } );
  const [ textX, setTextX ] = useState( 0 );
  const [ textY, setTextY ] = useState( 0 );
  const [ bgX, setBgX ] = useState( 0 );
  const [ bgY, setBgY ] = useState( 0 );
  const [ bgWidth, setBgWidth ] = useState( 0 );
  const [ bgHeight, setBgHeight ] = useState( 0 );
  // We intentionally had to use Ref instead of states.
  const clickListenerEnabledRef = useRef( false );

  const zoomZoneClicked = () => {
    clickListenerEnabledRef.current = true;
  };

  useEffect( () => {
    if ( mapInstance ) {
      mapInstance.addListener( 'click', ( event ) => {
        setTimeout( () => {
          // The timeout is required because the click on the map is getting
          // called before zoomZoneClicked.
          if ( !clickListenerEnabledRef.current ) {
            return;
          }
          setActiveZoom( mapZoomToEnableLevels );
          const location = new window.google.maps.LatLng( event.latLng.lat(), event.latLng.lng() );
          mapInstance.setCenter( location );
          clickListenerEnabledRef.current = false;

          // While in SiteSee guide tour, if the user decides to interact with the map by
          // using the zoom in button in the Map Controls toolbar or clicking a Zoom
          // Zone button triggering the detailed view, Tip 1 would fade out, Tips 2
          // and 3 would automatically be skipped, and Tip 4 would fade in.
          if ( siteSeeGuideMode === 'tour' && siteSeeGuideId < 4 ) {
            setSiteSeeGuideId( 4 );
           // setShowSiteSeeGuide( true );
          }
        }, 0 );
      } );
    }
  }, [ mapInstance, clickListenerEnabledRef ] );

  useEffect( () => {
    if ( textRef.current ) {
      const { width, height } = textRef.current.getBBox();
      setRectSize( { width: width + 30, height: height + 10 } );
    }
    if ( zoomZoneIconRef.current ) {
      setSvgSize( zoomZoneIconRef.current.getBBox() );
    }
  }, [ zoomZone ] );

  const horizontalTextPadding = 15;
  const verticalTextPadding = 7.5;

  useEffect( () => {
    if ( svgSize && rectSize && zoomZoneCenter && orientation ) {
      setBgWidth( rectSize.width );
      setBgHeight( rectSize.height );

      switch ( orientation ) {
        case 'top':
          setBgX( zoomZoneCenter.x - ( rectSize.width / 2 ) + ( svgSize.width / 2 ) );
          setBgY( zoomZoneCenter.y - svgSize.height );
          setTextX( zoomZoneCenter.x + horizontalTextPadding - ( rectSize.width / 2 ) + ( svgSize.width / 2 ) );
          setTextY( zoomZoneCenter.y + 2.5 - ( svgSize.height / 2 ) );
          break;
        case 'bottom':
          setBgX( zoomZoneCenter.x - ( rectSize.width / 2 ) + ( svgSize.width / 2 ) );
          setBgY( zoomZoneCenter.y + ( svgSize.height * 1.25 ) );
          setTextX( zoomZoneCenter.x + horizontalTextPadding - ( rectSize.width / 2 ) + ( svgSize.width / 2 ) );
          setTextY( zoomZoneCenter.y + rectSize.height + svgSize.height );
          break;
        case 'left':
          setBgX( zoomZoneCenter.x - rectSize.width - ( svgSize.width * 0.25 ) );
          setBgY( zoomZoneCenter.y + 5 );
          setTextX( zoomZoneCenter.x + horizontalTextPadding - rectSize.width - ( svgSize.width * 0.25 ) );
          setTextY( zoomZoneCenter.y + verticalTextPadding + ( svgSize.height / 2 ) );
          break;
        case 'right':
          setBgX( zoomZoneCenter.x + ( svgSize.width * 1.25 ) );
          setBgY( zoomZoneCenter.y + 5 );
          setTextX( zoomZoneCenter.x + horizontalTextPadding + ( svgSize.width * 1.25 ) );
          setTextY( zoomZoneCenter.y + verticalTextPadding + ( svgSize.height / 2 ) );
          break;
        default:
          break;
      }
    }
  }, [
    zoomZoneCenter, orientation, rectSize, svgSize,
  ] );

  return (
    <g className="zMarker individual-zoom-zone" onClick={zoomZoneClicked}>

      <ZoomZoneIcon
        x={zoomZoneCenter.x}
        y={zoomZoneCenter.y}
        ref={zoomZoneIconRef}
      />

      <rect
        rx="8"
        ry="8"
        x={bgX}
        y={bgY}
        width={bgWidth}
        height={bgHeight}
        className="zMarker__bg zMarker__visible"
      />
      <text
        x={textX}
        y={textY}
        className="zMarker__text zMarker__visible"
        ref={textRef}
      >
        {name}
      </text>
    </g>
  );
};

export default ZoomZone;
