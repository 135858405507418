import React, { useContext } from 'react';

import MapContext from '../../MapPage/MapContext';
import ZoomZone from './ZoomZone';

import './ZoomZone.scss';

const ZoomZones = ( {
  zoomZonesActivationLevel,
  zoomZones,
  siteSeeGuideId,
  setSiteSeeGuideId,
  showSiteSeeGuide,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
} ) => {
  const { activeZoom } = useContext( MapContext );
  if ( zoomZones && activeZoom >= zoomZonesActivationLevel ) {
    return (
      <>
        { Object.keys( zoomZones ).map( ( key ) => {
          // Key is taxonomy term id from Drupal.
          const zoomZone = zoomZones[key];
          return (
            <ZoomZone
              key={key}
              zoomZone={zoomZone}
              siteSeeGuideMode={siteSeeGuideMode}
              setSiteSeeGuideMode={setSiteSeeGuideMode}
              siteSeeGuideId={siteSeeGuideId}
              setSiteSeeGuideId={setSiteSeeGuideId}
              showSiteSeeGuide={showSiteSeeGuide}
              setShowSiteSeeGuide={setShowSiteSeeGuide}
            />
          );
        } ) }
      </>
    );
  }
  return null;
};

export default ZoomZones;
