import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import { patchResidentInDrupal } from '../../../Resident/ResidentHelpers';

const cookies = new Cookies();

const PersonalizeWelcomeMessage = ({
  cookieDataDump,
  isSubmitting,
  handleGoBack,
  onSaveWelcomeMessage,
  residentUuid,
}) => {
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [variations, setVariations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFirstRewrite, setIsFirstRewrite] = useState(true);
  const [isRephrasing, setIsRephrasing] = useState(false);
  const topRef = useRef(null);
  const textareaRef = useRef(null);

  const getNames = (conjunction = 'and') => {
    if (cookieDataDump.person2_first_name) {
      return `${cookieDataDump.first_name} ${conjunction} ${cookieDataDump.person2_first_name}`;
    }
    return cookieDataDump.first_name;
  };

  useEffect(() => {
    const savedMessage = cookies.get('welcome_message');
    if (savedMessage) {
      setWelcomeMessage(savedMessage);
    } else {
      setWelcomeMessage(
        `Hello ${getNames()}, I really enjoyed our conversation today! I hope the tour was enjoyable and that you're excited about everything our community has to offer. This is your Personalized Interactive Brochure, which includes all the floor plans and amenities you liked. Feel free to pass the link along to your family and friends. I'll touch base with you in a few days.`
      );
    }
  }, [cookieDataDump.first_name, cookieDataDump.person2_first_name]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [welcomeMessage]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleCloseClick = () => {
    handleGoBack();
  };

  const handleRewriteWithAI = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_YOUR_TOUR_CMS_URL}/your-tour-api/welcome-messages`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `message=${encodeURIComponent(welcomeMessage)}`,
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch welcome messages');
      }
      const data = await response.json();

      const newVariations = data.map((item) => ({
        text: item.message.replace('[NAME]', getNames()),
        canRephrase: true,
        rephrased: false,
      }));
      setVariations((prevVariations) => [...newVariations, ...prevVariations]);
      setIsFirstRewrite(false);
    } catch (err) {
      setError('Error fetching welcome messages. Please try again.');
      console.error('Error fetching welcome messages:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveWelcomeMessage = async () => {
    try {
      await saveWelcomeMessageToCookie(welcomeMessage);
      onSaveWelcomeMessage(welcomeMessage);
    } catch (error) {
      console.error('Error saving welcome message:', error);
      setError('Failed to save welcome message. Please try again.');
    }
  };

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSelectVariation = async (e, index) => {
    e.preventDefault();
    const selectedMessage = variations[index].text;
    setWelcomeMessage(selectedMessage);
    try {
      await saveWelcomeMessageToCookie(selectedMessage);
      onSaveWelcomeMessage(selectedMessage);
      scrollToTop();
    } catch (error) {
      console.error('Error saving selected variation:', error);
      setError('Failed to save selected variation. Please try again.');
    }
  };

  const handleRephraseWithAI = async (index) => {
    setError(null);
    setIsRephrasing(true);

    setVariations((prevVariations) =>
      prevVariations.map((v, i) =>
        i === index ? { ...v, canRephrase: false } : v
      )
    );

    const fetchWithRetry = async (retryCount = 0) => {
      try {
        const message = variations[index].text;
        const response = await fetch(
          `${process.env.REACT_APP_YOUR_TOUR_CMS_URL}/your-tour-api/rewrite-welcome-message`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `message=${encodeURIComponent(message)}`,
          }
        );

        if (!response.ok) {
          throw new Error('Failed to update welcome message');
        }

        const updatedMessage = await response.json();

        if (!updatedMessage || (!updatedMessage[1] && !updatedMessage[0])) {
          throw new Error('Invalid response format from API');
        }

        let messageContent;
        if (updatedMessage[1] && updatedMessage[1].message) {
          messageContent = updatedMessage[1].message;
        } else if (updatedMessage[0]) {
          messageContent = updatedMessage[0];
        } else {
          throw new Error('No valid message content found');
        }

        const newMessage = messageContent.replace('[NAME]', getNames());

        setVariations((prevVariations) => [
          {
            text: newMessage,
            canRephrase: false,
            rephrased: true,
          },
          ...prevVariations,
        ]);
      } catch (err) {
        if (retryCount < 1) {
          console.log('First attempt failed, retrying...');
          return fetchWithRetry(retryCount + 1);
        }
        throw err;
      }
    };

    try {
      await fetchWithRetry();
    } catch (err) {
      setError('Error updating welcome message. Please try again.');
      console.error('Error updating welcome message:', err);
    } finally {
      setIsRephrasing(false);
    }
  };

  const saveWelcomeMessageToCookie = async (message) => {
    cookies.set('welcome_message', message, {
      path: '/',
      maxAge: 14400, // 4 hour cookie expiration.
      domain: window.location.hostname,
    });

    try {
      await patchResidentInDrupal(
        residentUuid,
        { field_favorites_page_message: message },
        null,
        {}
      );
    } catch (error) {
      console.error('Error updating resident data:', error);
      throw error;
    }
  };

  return (
    <div className="personalize-welcome-message" ref={topRef} tabIndex="-1">
      <h3 className="personalization__form-header">
        Welcome Message to {getNames('&')}
      </h3>
      <div className="personalization__instructions">
        <p>
          Use our AI generator to create a personalized Welcome Message for{' '}
          {getNames()}. This message will appear at the top of their
          Personalized Interactive Brochure. Tweak the template below and click
          the "Rewrite Welcome Message" to see new variations from which to
          choose. New variations will be added to the top of the list.
        </p>
        <textarea
          ref={textareaRef}
          className="personalization__welcome-message-textarea"
          value={welcomeMessage}
          onChange={(e) => setWelcomeMessage(e.target.value)}
          style={{ overflow: 'hidden' }}
        />
      </div>
      <div className="personalization__inline-buttons">
        <button
          type="button"
          className="personalization__cta"
          onClick={handleRewriteWithAI}
          disabled={isSubmitting || isLoading}
        >
          {isLoading ? 'Loading...' : 'Rewrite with AI'}
        </button>
        <button
          type="button"
          className="personalization__cta-minor-alt"
          onClick={() => handleSaveWelcomeMessage().catch(console.error)}
          disabled={isSubmitting}
        >
          Save & Use as Welcome Message
        </button>
      </div>
      <div className="personalization__inline-buttons-close">
        <button
          type="button"
          className="personalization__cta-minor"
          onClick={handleCloseClick}
          disabled={isSubmitting}
        >
          Close (your message will not be saved.)
        </button>
      </div>
      <div className="personalization__welcome-message-variations">
        {error && (
          <div className="personalization__error-box">
            <p className="personalization__error-message">{error}</p>
          </div>
        )}
        {isRephrasing && (
          <div className="rephrasing-loading">
            <div className="content content--center">
              <LoadingSpinner />
            </div>
          </div>
        )}
        {variations.length > 0 ? (
          variations.map((variation, index) => (
            <div key={index} className="personalization__variation">
              <div className="personalization__variation-header">
                <h3 className="personalization__variation-title">
                  Variation {index + 1}
                  {variation.rephrased ? ' - Rephrased' : ''}
                </h3>
                <div className="personalization__variation-links">
                  {variation.canRephrase && (
                    <a
                      href="#"
                      className="personalization__variation-link"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRephraseWithAI(index);
                      }}
                    >
                      Rephrase with AI
                    </a>
                  )}
                  <a
                    href="#"
                    className="personalization__variation-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setWelcomeMessage(variation.text);
                      scrollToTop();
                    }}
                  >
                    Edit Manually
                  </a>
                </div>
              </div>
              <div className="personalization__variation-content">
                <p className="personalization__variation-text">
                  {variation.text}
                </p>
                <div className="personalization__variation-actions">
                  <button
                    onClick={(e) =>
                      handleSelectVariation(e, index).catch(console.error)
                    }
                    className="personalization__cta-minor-alt"
                  >
                    Save & Use as Welcome Message
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : isLoading && isFirstRewrite ? (
          <div className="no-messages">
            <div className="content content--center">
              <LoadingSpinner />
            </div>
          </div>
        ) : (
          <p className="no-messages">
            Your 2 Welcome Message variations will be listed here.
          </p>
        )}
      </div>
    </div>
  );
};

PersonalizeWelcomeMessage.propTypes = {
  cookieDataDump: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    my_favorites_page: PropTypes.string,
    person2_first_name: PropTypes.string,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  onSaveWelcomeMessage: PropTypes.func.isRequired,
  residentUuid: PropTypes.string.isRequired,
};

export default PersonalizeWelcomeMessage;
