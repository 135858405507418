import React from 'react';

const SiteSeeTipTrigger = ( {
  siteSeeGuideId,
  setSiteSeeGuideId,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
  siteSeeTipId,
} ) => siteSeeGuideMode === 'tip' && ( siteSeeGuideId !== siteSeeTipId ) && (
  <button
    className="siteSeeGuide__tipTrigger"
    type="button"
    onClick={
      () => {
        setSiteSeeGuideId( siteSeeTipId );
        setShowSiteSeeGuide( true );
      }
    }
  >
    ?
  </button>
);

export default SiteSeeTipTrigger;
