import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import MapContext from './MapContext';
import GoogleMap from './GoogleMap/GoogleMap';
import LevelsButtonList from '../Map/LevelsButtonList/LevelsButtonList';
import MapControls from '../Map/MapControls/MapControls';
import MapFilterMenu from './MapFilterMenu/MapFilterMenu';
import MapCompassRose from '../Map/MapCompassRose/MapCompassRose';
import SiteSeeGuides from '../SiteSee/SiteSeeGuides/SiteSeeGuides';

import './MapPage.scss';

const MapPage = ( {
  mapData,
  secondaryColor,
  isSiteSee,
} ) => {
  const [ activeLevelOfCareUuid, setActiveLevelOfCareUuid ] = useState( null );
  const [ activeLevelSvgId, setActiveLevelSvgId ] = useState( null );
  const [ activeLevelTid, setActiveLevelTid ] = useState( null );
  const [ initialLevelSvgId, setInitialLevelSvgId ] = useState( null );
  const [ activeFloorPlanUuids, setActiveFloorPlanUuids ] = useState( [] );
  const [ activeZoom, setActiveZoom ] = useState( null );
  const [ activeMapType, setActiveMapType ] = useState( 'roadmap' );
  const [ fitAndPan, setFitAndPan ] = useState( null );
  const [ initialOverlayWidth, setInitialOverlayWidth ] = useState( null );
  const [ currentOverlayWidth, setCurrentOverlayWidth ] = useState( null );
  const [ markersVisible, setMarkersVisible ] = useState( false );
  const [ overlayVisible, setOverlayVisible ] = useState( true );
  const [ filtersVisible, setFiltersVisible ] = useState( false );
  const [ markersToggleVisible, setMarkersToggleVisible ] = useState( false );
  const [ POIVisible, setPOIVisible ] = useState( false );
  const [ activeCategoryFilter, setActiveCategoryFilter ] = useState( [] );
  const [ activeFilterSelections, setActiveFilterSelections ] = useState( {} );
  const [ filteredCategoryData, setFilteredCategoryData ] = useState( {} );
  const [ mapActiveUnitData, setMapActiveUnitData ] = useState( {} );
  const [ mapZoomToEnableLevels, setMapZoomToEnableLevels ] = useState( null );
  const [ zoomSettings, setZoomSettings ] = useState( null );
  const [ mapIdle, setMapIdle ] = useState( false );
  const [ mapInstance, setMapInstance ] = useState( null );

  const [ siteSeeGuideId, setSiteSeeGuideId ] = useState( null );
  const [ showSiteSeeGuide, setShowSiteSeeGuide ] = useState( false );
  const [ siteSeeGuideMode, setSiteSeeGuideMode ] = useState( null );
  const [ siteSeeGuideRefs, setSiteSeeGuideRefs ] = useState( [] );
  const [ siteSeeModalDims, setSiteSeeModalDims ] = useState( {} );

  const { config, isKiosk } = useContext( ConfigContext );
  const { features, defaultLevelOfCare } = config;

  // Set whether markers are visible on load. This won't be on PIB.
  useEffect( () => {
    let showLabels = false;
    if ( isKiosk ) {
      showLabels = features?.includes( 'kiosk_show_map_place_labels_initially' );
    } else if ( isSiteSee ) {
      showLabels = features?.includes( 'sitesee_show_map_place_labels_initially' );
    } else {
      showLabels = features?.includes( 'yt_show_map_place_labels_initially' );
    }
    setMarkersVisible( showLabels );
  }, [
    isKiosk, isSiteSee, features,
  ] );

  // Set initial SVG level on load.
  useEffect( () => {
    if ( initialLevelSvgId ) {
      setActiveLevelSvgId( initialLevelSvgId );
    }
  }, [ initialLevelSvgId ] );

  // Set level of care for initial load.
  useEffect( () => {
    if ( defaultLevelOfCare ) {
      setActiveLevelOfCareUuid( defaultLevelOfCare );
    }
  }, [ defaultLevelOfCare ] );

  // Get and set zoom settings.
  const rawZoomSettings = mapData?.googleMapsConfig?.zooms;
  useEffect( () => {
    setZoomSettings( rawZoomSettings );
  }, [ rawZoomSettings ] );

  // Determine what zoom we should enable levels at.
  const rawMapZoomToEnableLevels = mapData?.mapZoomToEnableLevels || 1;
  useEffect( () => {
    setMapZoomToEnableLevels( rawMapZoomToEnableLevels );
  }, [ rawMapZoomToEnableLevels ] );

  // When the user returns, don't show the tour for 7 days.
  const setUserSiteSeeTourVisibilityCookie = () => {
    const EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds.

    const now = new Date().getTime();
    const userSiteSeeTourVisibilityCookie = {
      value: 'true',
      expiration: now + EXPIRATION_TIME,
    };

    localStorage.setItem( 'userSiteSeeTourVisibility', JSON.stringify( userSiteSeeTourVisibilityCookie ) );
  };

  // Check for localStorage of whether user has should be shown the tour.
  useEffect( () => {
    const siteSeeTour = JSON.parse( localStorage.getItem( 'userSiteSeeTourVisibility' ) );

    // Returning user.
    if ( siteSeeTour !== null ) {
      const now = new Date().getTime();
      if ( now < siteSeeTour.expiration ) {
        // Cookie is still valid, reset cookie and hide tour.
        setUserSiteSeeTourVisibilityCookie();
        setSiteSeeGuideMode( 'tip' );
      }
    }
    else {
      setUserSiteSeeTourVisibilityCookie();
    }
  }, [] );

  // Using useMemo to memoize the value object passed to MapContext.Provider
  // improves performance and readability by preventing unnecessary re-renders
  // of consuming components.
  const contextMemo = useMemo( () => ( {
    activeCategoryFilter,
    activeFilterSelections,
    activeFloorPlanUuids,
    activeLevelOfCareUuid,
    activeLevelSvgId,
    activeLevelTid,
    activeMapType,
    activeZoom,
    currentOverlayWidth,
    filteredCategoryData,
    fitAndPan,
    initialOverlayWidth,
    isSiteSee,
    mapActiveUnitData,
    mapData,
    mapZoomToEnableLevels,
    markersVisible,
    overlayVisible,
    POIVisible,
    mapIdle,
    setActiveCategoryFilter,
    setActiveFilterSelections,
    setActiveFloorPlanUuids,
    setActiveLevelOfCareUuid,
    setActiveLevelTid,
    setActiveMapType,
    setActiveZoom,
    setCurrentOverlayWidth,
    setFilteredCategoryData,
    setFitAndPan,
    setInitialOverlayWidth,
    setMapActiveUnitData,
    setPOIVisible,
    setMapIdle,
    setMapInstance,
    mapInstance,
  } ), [
    activeCategoryFilter,
    activeFilterSelections,
    activeFloorPlanUuids,
    activeLevelOfCareUuid,
    activeLevelSvgId,
    activeLevelTid,
    activeMapType,
    activeZoom,
    currentOverlayWidth,
    filteredCategoryData,
    fitAndPan,
    initialOverlayWidth,
    isSiteSee,
    mapActiveUnitData,
    mapData,
    mapZoomToEnableLevels,
    markersVisible,
    overlayVisible,
    POIVisible,
    mapIdle,
    setMapInstance,
    mapInstance,
  ] );

  return mapData && (
    <div className="map">

      { config && (
        <MapContext.Provider
          value={contextMemo}
        >
          <GoogleMap
            activeMapType={activeMapType}
            setFiltersVisible={setFiltersVisible}
            setMarkersToggleVisible={setMarkersToggleVisible}
            secondaryColor={secondaryColor}
            setOverlayVisible={setOverlayVisible}
            siteSeeGuideId={siteSeeGuideId}
            setSiteSeeGuideId={setSiteSeeGuideId}
            showSiteSeeGuide={showSiteSeeGuide}
            setShowSiteSeeGuide={setShowSiteSeeGuide}
            siteSeeGuideMode={siteSeeGuideMode}
          />

          <LevelsButtonList
            activeZoom={activeZoom}
            setActiveLevelSvgId={setActiveLevelSvgId}
            setInitialLevelSvgId={setInitialLevelSvgId}
            mapZoomToEnableLevels={mapZoomToEnableLevels}
            siteSeeGuideId={siteSeeGuideId}
            setSiteSeeGuideId={setSiteSeeGuideId}
            showSiteSeeGuide={showSiteSeeGuide}
            setShowSiteSeeGuide={setShowSiteSeeGuide}
            siteSeeGuideMode={siteSeeGuideMode}
            siteSeeGuideRefs={siteSeeGuideRefs}
            setSiteSeeGuideRefs={setSiteSeeGuideRefs}
            siteSeeModalDims={siteSeeModalDims}
            setSiteSeeModalDims={setSiteSeeModalDims}

          />

          <MapControls
            setActiveZoom={setActiveZoom}
            activeZoom={activeZoom}
            zoomSettings={zoomSettings}
            setOverlayVisible={setOverlayVisible}
            setMarkersVisible={setMarkersVisible}
            markersToggleVisible={markersToggleVisible}
            siteSeeGuideId={siteSeeGuideId}
            setSiteSeeGuideId={setSiteSeeGuideId}
            showSiteSeeGuide={showSiteSeeGuide}
            setShowSiteSeeGuide={setShowSiteSeeGuide}
            siteSeeGuideMode={siteSeeGuideMode}
            siteSeeGuideRefs={siteSeeGuideRefs}
            setSiteSeeGuideRefs={setSiteSeeGuideRefs}
            siteSeeModalDims={siteSeeModalDims}
            setSiteSeeModalDims={setSiteSeeModalDims}
          />

          <MapFilterMenu
            filtersVisible={filtersVisible}
            hideByFieldName="field_display_on_interactive_map"
            siteSeeGuideId={siteSeeGuideId}
            setSiteSeeGuideId={setSiteSeeGuideId}
            showSiteSeeGuide={showSiteSeeGuide}
            setShowSiteSeeGuide={setShowSiteSeeGuide}
            siteSeeGuideMode={siteSeeGuideMode}
            siteSeeGuideRefs={siteSeeGuideRefs}
            setSiteSeeGuideRefs={setSiteSeeGuideRefs}
            siteSeeModalDims={siteSeeModalDims}
            setSiteSeeModalDims={setSiteSeeModalDims}
          />

          {MapCompassRose}

        </MapContext.Provider>
      )}

      { isSiteSee && (
        <SiteSeeGuides
          siteSeeGuideId={siteSeeGuideId}
          setSiteSeeGuideId={setSiteSeeGuideId}
          showSiteSeeGuide={showSiteSeeGuide}
          setShowSiteSeeGuide={setShowSiteSeeGuide}
          siteSeeGuideMode={siteSeeGuideMode}
          setSiteSeeGuideMode={setSiteSeeGuideMode}
          setActiveZoom={setActiveZoom}
          mapZoomToEnableLevels={mapZoomToEnableLevels}
          siteSeeModalDims={siteSeeModalDims}
        />
      )}

    </div>
  );
};

MapPage.propTypes = {
  mapData: PropTypes.shape( {
    googleMapsConfig: PropTypes.shape( {
      zooms: PropTypes.shape( {
        min: PropTypes.number,
        max: PropTypes.number,
      } ),
    } ),
    mapZoomToEnableLevels: PropTypes.number,
  } ).isRequired,
  secondaryColor: PropTypes.string,
  isSiteSee: PropTypes.bool.isRequired,
};

MapPage.defaultProps = {
  secondaryColor: '#000000',
};

export default MapPage;
