import React, { useContext, useEffect, useState } from 'react';
import { getAuthClient } from '../../../utils/auth';

import FloorPlansItem from '../FloorPlansItem/FloorPlansItem';

import '../../../GlobalScss/grid.scss';
import ConfigContext from '../../../utils/ConfigContext/ConfigContext';

const auth = new getAuthClient();

const FloorPlansList = ( { floorPlans } ) => {
  const [ soldOutFps, setSoldOutFps ] = useState( [] );

  const config = useContext( ConfigContext );
  const { settings, featureFlags } = config;
  const {
    isKiosk,
    urlSlug,
  } = settings;

  // Is the sold out feature enabled for this platform?
  // Should we show pricing? This won't be on /map-embeds so we don't need to
  // check for it.
  let showSoldOut = false;
  let showPricing = true;
  if ( isKiosk ) {
    showSoldOut = featureFlags?.includes( 'kiosk_show_sold_out_status' );
    showPricing = featureFlags?.includes( 'kiosk_show_pricing' );
  } else {
    showSoldOut = featureFlags?.includes( 'yt_show_sold_out_status' );
    showPricing = featureFlags?.includes( 'yt_show_pricing' );
  }

  // Get sold out floor plans by uuid
  useEffect( () => {
    const fetchData = async () => {
      const url = `/your_tour_api/floor_plan_availability/${urlSlug}/?_format=json`;
      const data = await auth.fetchWithAuthentication( url, 'GET', null );
      setSoldOutFps( data?.sold_out );
    };
    if ( urlSlug ) {
      fetchData();
    }
  }, [ urlSlug ] );

  return (
    <div className="gridContainer floorplanCat-1">
      { floorPlans?.map( ( floorPlan ) => (
        <FloorPlansItem
          key={floorPlan?.id}
          data={floorPlan}
          showPricing={showPricing}
          showSoldOut={showSoldOut}
          remainingInventory={soldOutFps[floorPlan?.id]?.remaining_inventory}
        />
      ) ) }
    </div>
  );
};

export default FloorPlansList;
